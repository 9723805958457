.card-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	text-decoration: none;
}
.card-container > * {
	margin: 20px 0;
}
.image-container {
	width: 200px;
	height: 120px;
}

.card-image {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

@media screen and (max-width: 480px) {
	.image-container {
		width: 130px;
		height: 80px;
	}
}
@media screen and (max-width: 768px) {
	.image-container {
		width: 160px;
		height: 80px;
	}
}

.description {
	font-weight: 100;
	font-size: 24px;
	line-height: 24px;
	color: white;
	text-decoration: none;
	cursor: pointer;
}
