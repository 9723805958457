.footer-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex: 1;
	flex-wrap: wrap;
}

.footer-left-part {
	border-top: 1px solid white;
	padding-top: 30px;
	font-style: normal;
	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
	flex: 1;
	margin-right: 10px;
	font-family: 'Lato', sans-serif;
}

.footer-right-part {
	border-top: 1px solid white;
	padding-top: 30px;
	text-align: right;
	font-weight: 100;
	font-size: 16px;
	line-height: 24px;
	flex: 1;
	margin-left: 10px;
	font-family: 'Yekan';
}

.footer-container p {
	color: white;
	margin: 0;
}

@media screen and (max-width: 480px) {
	.rect {
		display: none;
	}
	.footer-container {
		flex-direction: column;
	}
	.footer-container > * {
		margin: 20px 0;
	}
}
