.card-list-container {
	display: flex;
	justify-content: space-around;
	width: 100%;
	flex: 2;
	flex-wrap: wrap;
}

.card-list-container > * {
	margin: 30px 0;
}

@media screen and (max-width: 768px) {
	.card-list-container {
		flex-direction: column;
	}
}
