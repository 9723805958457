.logo-container {
	width: 450px;
	flex: 1.5;
}

@media screen and (max-width: 1024px) {
	.logo-container {
		width: 400px;
	}
}
@media screen and (max-width: 768px) {
	.logo-container {
		width: 350px;
	}
}

@media screen and (max-width: 480px) {
	.logo-container {
		width: 250px;
	}
}

@media screen and (max-width: 376px) {
	.logo-container {
		width: 200px;
	}
}

.logo {
	width: 100%;
	height: 100%;
}
